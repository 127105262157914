export const IS_PRODUCTION = true;
export const MYKINDO_ATMY_API = IS_PRODUCTION ? "https://api.shop.kindo.co.nz/api/schools?brand_name=ezlunch" : "https://test.api.shop.kindo.co.nz/api/schools?brand_name=ezlunch"
export const MYKINDO_BASE_URL = IS_PRODUCTION ? "https://shop.kindo.co.nz/shop" : "https://test.shop.kindo.co.nz/shop"
export const MYKINDO_LOGIN_URL = IS_PRODUCTION ? "https://shop.kindo.co.nz/app/login" : "https://test.shop.kindo.co.nz/app/login"
export const MYKINDO_JOIN_URL = IS_PRODUCTION ? "https://shop.kindo.co.nz/app/login#register" : "https://test.shop.kindo.co.nz/app/login#register"
export const MYKINDO_ATMY_URL = IS_PRODUCTION ? "https://shop.kindo.co.nz/shop/atmy.shtml" : "https://test.shop.kindo.co.nz/shop/atmy.shtml"
export const FACEBOOK_APP_ID = "350010150010705"

export const EMAIL_SCHOOL_SIGNUP_PITCH: string = (() => {
  const subject = "I’ve seen this lunch service that would be great at our school"
  const body = `I’ve just been investigating ezlunch, who work with local cafes and caterers to provide fresh made food for school lunches.\
   They’re a NZ company that operates nationwide, and I think this is something that would be great at our school!
  There’s more info on their website www.ezlunch.co.nz. Thanks for taking a look.`

  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
})()

export const SCHOOL_ALIAS_LIST: [string, string][] = [['st ', 'saint '], ['mt ', 'mount '], ['pt ', 'point '], ['south ', 'sth '], ['north ', 'nth ']]