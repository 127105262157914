import React, { useCallback, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import * as styles from "./Nav.module.scss"
import { useAppContext } from "../../AppContext"
import { getPathForLocale, triggerGtagEvent } from "../../../util/util"
import { GatsbyImage } from "gatsby-plugin-image"
import { IUrlSharp } from "../../../model/IUrlSharp"
import { IPhoneNumber } from "../../../model/IPhoneNumber"
import { RaisedButton } from "../../button/RaisedButton"
import { MyKindoLogin } from "../../button/MyKindoLogin"
import { ResponsiveState, useResponsiveState } from "../../../hook/responsive_state"
import PhoneIcon from "../../../images/contact_icon_phone.svg"
import HamburgerIcon from "../../../images/hamburger_icon.svg"
import { useWindowWidth } from "../../../hook/window_size"
import { NavDrawer } from "./NavDrawer"
import { BasicPhoneDetails } from "../../BasicPhoneDetails"
import { mergeClassNames } from "../../../util/util"
import { MYKINDO_LOGIN_URL } from "../../../config"

export interface NavPhoneData {
  localNumber: IPhoneNumber,
  nationalNumber: IPhoneNumber
}

export interface NavPageLinkData {
  id: string,
  title: string,
  page: {
    slug: string | null
  }
}

export interface NavPageLinksData {
  navLinks: NavPageLinkData[]
}

interface NavData {
  strapi: {
    globalConfig: {
      Favicon: {
        urlSharp: IUrlSharp
      },
      shrunkFavicon: {
        urlSharp: IUrlSharp
      },

    } & NavPhoneData & NavPageLinksData
  }
}
const Nav: React.FC = () => {
  const {currentLocale} = useAppContext()

  const windowWidth = useWindowWidth()

  const doMinimiseMyKindoLogin = windowWidth <= 1118
  const doHideMyKindoLogin = windowWidth <= 1010

  const data = useStaticQuery<NavData>(query)
  const config = data.strapi.globalConfig

  return (
    <header className={styles.Nav}>
      <div className={styles.logoContainer}>
        <Link to={getPathForLocale(currentLocale, '/')} title='Home'>
          {(doMinimiseMyKindoLogin) ? (
            <GatsbyImage
              image={config.shrunkFavicon.urlSharp.childImageSharp.gatsbyImageData}
              alt='Ezlunch Logo'
            />
          ) : (
            <GatsbyImage image={config.Favicon.urlSharp.childImageSharp.gatsbyImageData} alt='Ezlunch Logo'/>
          )}
        </Link>
      </div>

      <div className={styles.links}>
        <NavPageLinks links={config} phone={config}/>

        <NavPhoneDetails localNumber={config.localNumber} nationalNumber={config.nationalNumber} />

        <div className={styles.actionButtons}>
          <RaisedButton onClick={() => {
            triggerGtagEvent({
              action: "click",
              label: 'mykindo_login'
            })
            window.open(MYKINDO_LOGIN_URL, "_blank")
          }}>
            ORDER LUNCH
          </RaisedButton>

          {(doHideMyKindoLogin) ? undefined : (
            <span style={{
              paddingLeft: (doMinimiseMyKindoLogin) ? 8 : 0
            }}>
              <MyKindoLogin minimised={doMinimiseMyKindoLogin}/>
            </span>
          )}
        </div>
      </div>
    </header>
  )
}

const NavPageLinks: React.FC<{ links: NavPageLinksData, phone: NavPhoneData }> = ({links, phone}) => {
  const [showingHamburgerMenu, setShowingHamburgerMenu] = useState<boolean>(false)

  const windowWidth = useWindowWidth()
  const {currentLocale, currentSlug} = useAppContext()

  const dismissHamburgerMenuCallback = useCallback(() => {
    setShowingHamburgerMenu(false)
  }, [setShowingHamburgerMenu])

  if (windowWidth <= 770) {
    return (
      <>
        <NavDrawer links={links} phone={phone} shown={showingHamburgerMenu} onHide={dismissHamburgerMenuCallback}/>
        <div className={styles.drawerTriggerContainer}>
          <HamburgerIcon
            className={styles.hamburgerIcon}
            onClick={() => setShowingHamburgerMenu(true)}
          />
        </div>
      </>
    )
  }
  else {
    return (
      <div className={styles.pageLinks}>
        {links.navLinks.map((link) => (
          <Link
            key={`Nav-NavLink-${link.id}`}
            className={`${styles.navLink}${((link.page.slug ?? "") === currentSlug) ? ` ${styles.navLinkActive}` : ''}`}
            to={getPathForLocale(currentLocale, link.page.slug)}
          >
            {link.title.toUpperCase()}
          </Link>
        ))}
        <div className={styles.subnav}>
          {/* <Link
            className={mergeClassNames(styles.navLink, (currentSlug === 'contact') ? styles.navLinkActive : undefined)}
            to={getPathForLocale(currentLocale, "contact")}
          >
            CONTACT
          </Link> */}
          <a
            href="/contact"
            className={mergeClassNames(styles.navLink, (currentSlug === 'contact') ? styles.navLinkActive : undefined)}
          >
            CONTACT
          </a>
        </div>
        <div className={styles.subnav}>
          <a className={mergeClassNames(styles.subnavbtn, ((currentSlug === 'about' || currentSlug === 'news')) ? styles.navLinkActive : undefined)}> ABOUT <span className={styles.caretDown}/></a>
          <div className={styles.subnavContent}>
            <Link style={{marginTop: '10px'}} to={getPathForLocale(currentLocale, "about")} className={mergeClassNames(styles.navLink, (currentSlug === 'about') ? styles.navLinkActive : undefined)}>OUR STORY</Link>
            <Link style={{marginTop: '35px'}} to={getPathForLocale(currentLocale, "news")} className={mergeClassNames(styles.navLink, (currentSlug === 'news') ? styles.navLinkActive : undefined)}>NEWS</Link>
            <a style={{marginTop: '60px'}} href='https://kindo.co.nz/joinourteam/' target='_blank' rel="noreferrer" className={styles.navLink}>JOIN OUR TEAM</a>
          </div>
        </div>
      </div>
    )
  }
}

const NavPhoneDetails: React.FC<NavPhoneData> = ({localNumber, nationalNumber}) => {
  const responsiveState = useResponsiveState()

  if (responsiveState < ResponsiveState.DESKTOP) {
    return (
      <div className={styles.phoneDetails}>
        <Link to='/contact'><PhoneIcon className={styles.phoneIcon}/> <span>call free</span></Link>
      </div>
    )
  }

  return (
    <BasicPhoneDetails localNumber={localNumber} nationalNumber={nationalNumber} className={styles.phoneDetails}/>
  )
}

export default Nav

const query = graphql`
  query {
    strapi {
      globalConfig {
        localNumber {
          ...phoneNumberFields
        }
        nationalNumber {
          ...phoneNumberFields
        }
        Favicon {
          url
          urlSharp {
            childImageSharp {
              gatsbyImageData(width: 100, quality: 100)
            }
          }
        }
        shrunkFavicon: Favicon {
          url
          urlSharp {
            childImageSharp {
              gatsbyImageData(width: 50, quality: 100)
            }
          }
        }
       
        navLinks {
            id
            title
            page {
              slug
            }
          }
      }
    }
  }
`