import React, { useCallback } from "react"
import * as styles from "./MyKindoLogin.module.scss"
import { MyKindoLogo } from "../MyKindoLogo"
import { StaticImage } from "gatsby-plugin-image"
import { mergeClassNames, triggerGtagEvent } from "../../util/util"
import { MyKindoButton } from "./MyKindoButton"
import { MYKINDO_LOGIN_URL } from "../../config"

interface MyKindoLoginProps {
  minimised: boolean
}
export const MyKindoLogin: React.FC<MyKindoLoginProps> = ({minimised = false}) => {
  const onClickCallback = useCallback(() => {
      triggerGtagEvent({
        action: "click",
        label: 'mykindo_login'
      })

    window.open(MYKINDO_LOGIN_URL, "_blank")
  }, [])

  if (minimised) {
    return (
      <MyKindoButton onClick={onClickCallback}>
        <MyKindoLogo fontSize={16}/>
      </MyKindoButton>
    )
  }
  else {
    return (
      <div className={mergeClassNames(styles.MyKindoLogin, styles.maximised)} onClick={onClickCallback}>

        <div className={styles.clipon}>
          <StaticImage className={styles.clipImage} src="../../images/green_clip.png" alt="" width={56} height={51}/>
          <div className={styles.clipContent}>
            <div>
              powered by
            </div>
            <StaticImage className={styles.clipLogo} src="../../images/kindo_logo.png" alt="kindo"/>
          </div>
        </div>

        <div className={styles.content}>
          <MyKindoLogo fontSize={19}/>
          <span className={styles.subtitle}>create account</span>
          <span className={styles.subtitle}>or <span className={styles.bold}>login</span></span>
        </div>
      </div>
    )
  }
}